import React from "react"
import { graphql, Link } from "gatsby"

import '../css/pagination.css';

const Pagination = ({ uri, currentPage, numPages }) => {
  let paginationOffset = 4;
  
  return (
    <div className="pagination">
      { currentPage - paginationOffset > 0 && 
        <React.Fragment>
          <Link className='first-page' to={`${uri}`}>
            1
          </Link>
          <span className="dots">...</span>
        </React.Fragment>
      }

      { (numPages > 1) &&
        Array.from({ length: numPages }, (_, i) => {
          i += 1;
          let className = 'page-num';

          if( !(i >= currentPage-3) || !(i <= currentPage+3) ) {
            return;
          }

          if(currentPage == i) {
            className += ' current-page'
            return (
              <span key={`pagination-number${i}`} className={className}>
                {i}
              </span>
            )
          } else {
            return (
              <Link key={`pagination-number${i}`} className={className} to={`${uri}${i === 1 ? "" : 'page/'+(i)}`}>
                {i}
              </Link>
            )
          }
        })
      }

      { (currentPage + paginationOffset) <= numPages && 
        <React.Fragment>
          <span className="dots">...</span>
          <Link className='last-page' to={`${uri}page/${numPages}`}>
            {numPages}
          </Link>
        </React.Fragment>
      }
    </div>
  )
}

export default Pagination