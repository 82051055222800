import React from "react"
import { graphql } from "gatsby"

import '../css/featured-post.css';

const FeaturedPost = ({ post }) => {

  const { title, link, excerpt, date, featuredImage, categories } = post;

  let catList = '';
  if(categories && categories.nodes) {
    categories.nodes.map((cat, index) => {
      catList += index == 0 ? cat.name : ', '+cat.name;
    })
  }

  return (
    <div className="container blogFeatured">
      <div className="grid smallGrid align-center">
        <div className="col-md-6 blogFeaturedRight">
          { featuredImage && featuredImage.node &&
            <img src={featuredImage.node.localFile.publicURL} className="flex-img" alt={featuredImage.node.altText} />
          }
        </div>
        <div className="col-md-6 maincopy">
          {/* <p className="blogDate">{date}</p> */}
          <h2 className="blogFeaturedTitle">{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>

            <div className="blogBoxFeatured">
              <div className="grid">
                <div className="col-sm-7">
                  <p className="blogBoxCatFeat">{catList}</p>
                </div>
                <div className="col-sm-5">
                  <a className="blogBoxLinkFeat" href={link}>Read More</a>
                </div>
              </div>
            </div>


        </div>
      </div>
    </div>
  )
}

export default FeaturedPost